export default function Placholder(props) {
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="h-48 w-48">
        <svg
          data-v-fb81cd8c=""
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 80.7 84.2"
        >
          <g fill="#FFF">
            <path d="M61.1 41.4c.3-.6.8-.9 2.5-1.5 1.1-.4 3-1 3.8-.9 1.1 0 1.4 1.2 1 2.2-1-.5-4.1-.7-5.9-.1M21.6 47c1-3.1 1.6-6 1.5-7.6.6-.2 1.9-.6 2.4-.2.4.3.6.8.5 1.5.5-1.3 1.2-1.9 2.4-1.9 1.1 0 1.6 1.1 1.6 2.4 0 1.6-.7 3.7-2.1 5-1 .9-2.4 1-3.4 1-.3 1.2-.5 2.3-.5 4.1-2.9.2-4-.8-2.4-4.3zm2.9-.5c.5-.2 1-.6 1.4-1.3.6-1.2 1-2.8 1.1-4.1.1-.5 0-.8-.2-.8-.3 0-.8 1.3-1 2.2-.4 1.1-.9 2.6-1.3 4z"></path>
            <path d="M29.2 47c1-3.1 1.6-6 1.5-7.6.6-.2 1.9-.6 2.4-.2.4.3.5.8.4 1.6.5-1.1 1.2-1.7 2.3-1.7 1.1 0 1.8.9 1.8 2.4s-.7 3.3-2.1 4.6c-1.1 1-2.5 1.4-3.5 1.1-.3 1.1-.4 2.2-.5 3.9-2.7.4-3.8-.6-2.3-4.1zm3-.4c.6-.1 1-.6 1.4-1.4.6-1.1 1-2.5 1.1-3.7.1-.5 0-.8-.2-.8-.4 0-.8 1.1-1.1 1.8-.4 1.1-.8 2.6-1.2 4.1zM41.4 45.8c-.7 1.3-1.6 1.4-2.2 1.4-1.1 0-1.8-.9-1.8-2.3 0-2.4 1.6-6 4.1-6 .5 0 .9.3 1.1 1 .1-.5.1-.9.1-1.2 1.2-.1 2.7-.1 2.9 1.2.1.8-.2 1.7-.7 2.7-.3 1.1-.6 2-.6 2.7 0 .3.1.4.2.4.3 0 1-.6 1.7-2.2.3.1.6.3.4.7-.8 1.8-2.3 3.1-3.9 3.1-.7-.2-1.2-.6-1.3-1.5zm.7-3.6c.1-.3.2-.6.3-1 0-.6-.1-1-.3-1-.7 0-1.5 3.3-1.7 4.8 0 .6 0 .8.2.8.3.1 1.1-2 1.5-3.6z"></path>
            <path d="M47.1 45.7c0-1.2.9-3.5 1.3-4.4-.2.1-.5.1-.6.1-.4 1-.7 1.8-1.1 2.6-.2.4-.6.3-.8 0 .5-1 .8-1.7 1.2-2.8-.5-.2-.8-.8-.8-1.5 0-1.1.4-2.2 1.2-2.5.6-.2 1.2-.1 1.6.5-.1.3-.6 1.1-.7 2-.1.4-.1.7.1.7.3 0 1.3-1.2 2.7-1.2.3 0 .8.2.8.5-.3.6-1.9 4.3-1.9 5.6 0 .3.1.5.3.5.4 0 1.3-1 2-2.5.5.2.6.5.3 1-.7 1.4-2 3-3.9 3-1.2 0-1.7-.7-1.7-1.6z"></path>
            <path d="M55.8 45.8c-.7 1.3-1.7 1.4-2.3 1.4-1.2 0-1.8-.9-1.8-2.3 0-2.3 1.5-5.5 3.9-5.9.8-.1 1.3.1 1.7.4.5-1.3 2.2-.6 3 .2-.4.6-1 2.4-1.4 3.8-.2.6-.3 1.2-.3 1.7 0 .2.1.4.4.5.2 0 .5 0 .8-.1-.2 1.1-1.1 1.7-2.3 1.7-1.1.1-1.6-.5-1.7-1.4zm.4-2.4c.2-.7.6-1.8.9-2.9-.1-.4-.3-.5-.5-.5-.8 0-1.7 3.7-1.9 5.1 0 .6 0 .8.2.8.4 0 1-1.5 1.3-2.5z"></path>
            <path d="M65.1 43.4c-.9 1.8-1.5 2.4-2 2.4-.3 0-.4-.2-.4-.7 0-1.1.8-3.7 1.3-5.2l.3-1c.3-.7.4-1.5.4-1.9 0-1.6-1.2-1.8-3-1.1.1.8.1 1.5-.2 2.5-.3 1.1-1.2 4.3-1.6 5.7-.4 1.3-.4 3.3 1.7 3.3 1.7 0 2.8-1.1 3.8-3.1.3-.5.1-.8-.3-.9zM17.9 45.8c-.8 1.4-1.7 1.4-2.3 1.4-1.2 0-1.8-.9-1.8-2.4 0-2.3 1.5-5.6 4-6 .9-.1 1.3.1 1.7.4.5-1.3 2.2-.7 3 .2-.4.6-1 2.4-1.4 3.9-.2.6-.3 1.3-.3 1.7 0 .2.1.4.4.5.2 0 .5 0 .8-.1-.2 1.1-1.1 1.8-2.4 1.8-1.1.1-1.6-.6-1.7-1.4zm.4-2.5c.2-.7.6-1.8.9-3-.1-.4-.3-.5-.5-.5-.8 0-1.7 3.7-1.9 5.1 0 .6 0 .8.2.8.4.1 1-1.4 1.3-2.4zM30.5 50.3c3.5-1 10.6-3.3 14.7-2.3 2.6.6 2.2 3.5 1.7 4.5-4.2-1.3-7.7-1.4-11.7-1.3-.9 0-1.8 0-2.7.1l-1.4.1"></path>
          </g>
          <path
            fill="none"
            stroke="#FFF"
            stroke-width=".564"
            stroke-linecap="round"
            stroke-miterlimit="10"
            d="M6.6 34C2.4 50.1 10 67.4 25.5 74.9c17.7 8.6 39 1.2 47.6-16.5s1.2-39-16.5-47.6c-7-3.4-14.5-4.3-21.5-3.1"
          ></path>
          <g
            fill="none"
            stroke="#FFF"
            stroke-width="1.129"
            stroke-linecap="round"
            stroke-miterlimit="10"
          >
            <path d="M33.2 73.4h0"></path>
            <path
              stroke-dasharray="0,3.3335"
              d="M36.5 74.1c12.8 1.9 25.8-4.2 32.3-16"
            ></path>
            <path d="M69.6 56.6h0"></path>
          </g>
          <g
            fill="none"
            stroke="#FFF"
            stroke-width="1.129"
            stroke-linecap="round"
            stroke-miterlimit="10"
          >
            <path d="M25.2 11.1h0"></path>
            <path
              stroke-dasharray="0,3.1584"
              d="M22.4 12.6c-5.1 3.1-9.2 7.4-12 12.3"
            ></path>
            <path d="M9.7 26.3h0"></path>
          </g>
        </svg>
      </div>
    </div>
  );
}
